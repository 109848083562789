import React, { useRef } from "react";
import image1 from "../assets/footer-logo2.png";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { NavLink } from "react-router-dom";
export default function Footer() {
  const form = useRef();
  const notify = () => toast("Subscribed!");
  const notify2 = () => toast("Error sending email, pls try again later!");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_42oxjkr",
        "template_he1607s",
        form.current,
        "mPVSvcQ8zSxxfGmd3"
      )
      .then((response) => {
        notify();
        console.log("Email successfully sent!", response);
      })
      .catch((error) => {
        notify2();
        console.error("Error sending email:", error);
        // Handle the error as needed (e.g., display an error message to the user)
      });
  };

  return (
    <div className="footer">
      <div className="containers">
        <div className="footer-flex">
          <div className="footer-logo">
            <img src={image1} alt="arms4ai" />
          </div>
          {/* <div className="footer-link">
            <h6>Links</h6>
            <p>Solutions</p>
            <p>Technology</p>
            <p>Pricing</p>
            <p>Contact</p>
          </div> */}
          <div
            className="footer-social"
            style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}
          >
            <h6>Company</h6>
            <NavLink to="/">Home</NavLink>

            <NavLink to="/about">About</NavLink>

            <NavLink to="/product">Product</NavLink>
            <NavLink to="/industry">Industry Verticals</NavLink>
            <NavLink to="/contact">Contact Us</NavLink>
          </div>
          <div className="footer-social">
            <h6>Social Links</h6>
            <a class="" href="https://www.linkedin.com/company/arms4ai/">
              <FaLinkedin className="linkedin-icon" />
            </a>

            <a class="" href="https://twitter.com/Arms4AI1">
              <FaTwitter className="linkedin-icon" />
            </a>
          </div>
          <div className="footer-subs">
            <h6>Subscribe to our Newsletter</h6>
            <form ref={form} onSubmit={sendEmail}>
              <input
                className="contact-input"
                type="text"
                name="email"
                placeholder="Enter you mail id"
                required
              />
              <input
                type="submit"
                value="Subscribe"
                className="contact-btn"
                style={{ cursor: "pointer" }}
              />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
}
