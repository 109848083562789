import React, { useEffect } from "react";

import "./intro.css";

export default function IntroductionGeo() {
  useEffect(() => {
    var intro = document.getElementById("intro-geo");
    var introHead = document.getElementById("intro-holder-agri");
    const fadingel1 = document.querySelectorAll(".prepare-div");
    const fadingel3 = document.querySelectorAll(".objective");
    const fadingel4 = document.querySelectorAll(".objective-details");
    const fadingel5 = document.querySelectorAll(".objective-list");
    const fadingel6 = document.querySelectorAll(".prepare");
    document.addEventListener("scroll", function () {
      var rect = intro.getBoundingClientRect();
      var rectHead = introHead.getBoundingClientRect();
      if ((rectHead.top / window.innerWidth) * 100 < 0) {
        intro.style.transition = "0.5s";
      } else {
        introHead.style.background = "transparent";

        intro.style.transition = "0s";
      }
      if ((rectHead.top / window.innerWidth) * 100 < -20) {
        intro.style.opacity = "0";
      } else {
        intro.style.opacity = "1";
      }
      if (
        (rect.top / window.innerWidth) * 100 < 80 &&
        (rect.top / window.innerWidth) * 100 > 20
      ) {
        intro.style.fontSize =
          (100 - (rect.top / window.innerWidth) * 100) * 0.05 + "vw";
      }

      fadingel1.forEach((el) => fading(el));
      fadingel3.forEach((el) => fading(el));
      fadingel4.forEach((el) => fading(el));
      fadingel5.forEach((el) => fading(el));
      fadingel6.forEach((el) => fading(el));
      function fading(el) {
        var rect1 = el.getBoundingClientRect();
        var isAtTop = rect1.top <= 100;
        if (isAtTop) {
          el.classList.add("fade");
        } else {
          el.classList.remove("fade");
        }
      }
    });
  }, []);

  return (
    <div>
      <div className="introducing-heading containers" id="intro-holder-agri">
        <h1
          id="intro-geo"
          className="intro-geo-h hidden lg:block text-gradient-heading"
        >
          Introducing AGRO - AI Platform
        </h1>
        <h1
          id="intro-geo-small"
          style={{ paddingTop: "10vh" }}
          className="intro-geo-h-small block lg:hidden text-[24px] text-gradient-heading"
        >
          Introducing <br />
          AGRO - AI Platform
        </h1>
      </div>
    </div>
  );
}
