import React, { Component } from "react";
import ProductList from "../components/ProductList";
import PageLoader from "../components/PageLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import image1 from "../assets/2(1).webp";
import group4 from "../assets/Group1.png";
import group2 from "../assets/Group2.png";
import group6 from "../assets/Group3.png";
import group7 from "../assets/Group4.png";

import IntroductionAgro from "../components/IntroductionAgro";
export default class Product extends Component {
  componentDidMount() {
    var img1 = document.getElementById("about-img1");
    if (img1 !== null) {
      document.addEventListener("scroll", function () {
        if (
          (window.scrollY / window.innerWidth) * 100 > 30 &&
          (window.scrollY / window.innerWidth) * 100 < 70
        ) {
          const newWidth =
            50 + ((window.scrollY / window.innerWidth) * 100) / 2;
          console.log(newWidth);
          img1.style.width = newWidth + "%";
        }
      });
    }
  }

  render() {
    return (
      <>
        <PageLoader />
        <div className="bg-eclipse-dark relative ">
          <div className="containers">
            <div className="pb-[200px] lg:pb-[10vw] pt-[200px] lg:pt-[15vw]">
              <h1 className="text-center text-white text-[28px] lg:text-[4vw] leading-tight  font-extrabold stroke-title relative z-[3]">
                Transform Imagery, Elevate Insights <br /> One click excellence
                with{" "}
                <font className="golden-text stroke-title">90% Accuracy</font>
              </h1>
            </div>

            <div className="lg:flex lg:gap-[10vw] justify-center">
              <div className="flex gap-[20px] lg:gap-[2vw]">
                <div>
                  <LazyLoadImage
                    src={group6}
                    alt=""
                    className="w-[40px] h-[40px] lg:w-[3vw] lg:h-[3vw]"
                  />
                </div>
                <div className="w-[70%] lg:w-[auto]">
                  <h2 className="rozha text-[18px] lg:text-[1.5vw]">
                    Automation and Precision
                  </h2>
                  <p className="text-[12px] lg:text-[0.9vw] w-[100%] lg:w-[18vw] leading-snug">
                    Automate processes with precision, guaranteeing accurate
                    outcomes and streamlined agricultural operations.
                  </p>
                </div>
              </div>
              <div className="flex gap-[20px] lg:gap-[2vw] lg:mt-[0]">
                <div>
                  <LazyLoadImage
                    src={group2}
                    alt=""
                    className="w-[40px] h-[40px] lg:w-[3vw] lg:h-[3vw]"
                  />
                </div>
                <div className="w-[70%] lg:w-[auto]">
                  <h2 className="rozha text-[18px] lg:text-[1.5vw]">
                    Near Real Time Output
                  </h2>
                  <p className="text-[12px] lg:text-[0.9vw] w-[100%] lg:w-[18vw] leading-snug">
                    Get near real-time output, enabling timely insights for
                    swift decision-making in agricultural management.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:flex lg:gap-[10vw] justify-center mt-[0] lg:mt-[5vw]">
              <div className="flex gap-[20px] lg:gap-[2vw] mt-[30px] lg:mt-[0]">
                <div>
                  <LazyLoadImage
                    src={group7}
                    alt=""
                    className="w-[40px] h-[40px] lg:w-[3vw] lg:h-[3vw]"
                  />
                </div>
                <div className="w-[70%] lg:w-[auto]">
                  <h2 className="rozha text-[18px] lg:text-[1.5vw]">
                    Easily Scalable
                  </h2>
                  <p className="text-[12px] lg:text-[0.9vw] w-[100%] lg:w-[18vw] leading-snug">
                    Effortlessly Scalable, facilitating seamless adaptation to
                    evolving demands and integration across diverse agricultural
                    landscapes.
                  </p>
                </div>
              </div>
              <div className="flex gap-[20px] lg:gap-[2vw] mt-[30px] lg:mt-[0]">
                <div>
                  <LazyLoadImage
                    src={group4}
                    alt=""
                    className="w-[40px] h-[40px] lg:w-[3vw] lg:h-[3vw]"
                  />
                </div>
                <div className="w-[70%] lg:w-[auto]">
                  <h2 className="rozha text-[18px] lg:text-[1.5vw]">
                    Pixel Wise Analysis
                  </h2>
                  <p className="text-[12px] lg:text-[0.9vw] w-[100%] lg:w-[18vw] leading-snug">
                    Enables pixel-wise analysis, providing granular insights for
                    targeted interventions and optimized agricultural practices.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <LazyLoadImage
              src={elipse2}
              alt=""
              className="absolute top-[28vw] w-[100%] hidden lg:block"
            />
            <LazyLoadImage
              src={elipse1}
              alt=""
              className="absolute top-[400px] w-[100%] block lg:hidden"
            />

            <div style={{ marginTop: "-200px" }}>
              <svg
                width="1920"
                height="476"
                viewBox="0 0 1920 456"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  id="ellipse-path"
                  cx="828"
                  cy="995"
                  rx="1334"
                  ry="994"
                  stroke="#B6AFAF"
                  stroke-width="2"
                />
                <circle class="moving-circle" r="10" fill="white" />
                <circle class="moving-circle-2" r="10" fill="white" />
              </svg>
            </div>
          </div> */}
        </div>
        <div
          className="mx-auto pb-[150px] lg:pb-[150px] pt-[0px] lg:pt-[150px]"
          style={{ background: "#EFEAF4" }}
        >
          <IntroductionAgro />
        </div>
        <div
          className="containers py-[200px] lg:py-[15vw]"
          style={{
            position: "relative",
            zIndex: "3",
            backgroundColor: "#EFEAF4",
          }}
        >
          <div className="about-hero">
            <div
              className="about-hero-img "
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LazyLoadImage
                src="https://arms4airesources.s3.amazonaws.com/2(1).webp"
                alt="arms4ai"
                id="about-img1"
                className="w-[80%] hidden lg:block"
              />
              <LazyLoadImage
                src="https://arms4airesources.s3.amazonaws.com/2(1).webp"
                alt="arms4ai"
                className="w-[100%] block lg:hidden"
              />
            </div>
          </div>
        </div>

        <ProductList />
      </>
    );
  }
}
