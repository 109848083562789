import React, { useRef } from "react";
import { goToPrevious, goToNext } from "../helpers/CarousalSlider";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import image2 from "../assets/recenttt.webp";
import image3 from "../assets/Rectangle4.png";

export const blog = [
  {
    title: "Tech Solutions Powered by Artifical Intelligence",
    subTitle:
      "Arms 4 AI uses technology-driven tools to support strategic decision-making and provide information to strengthen national security",
    url: "https://spanmag.com/tech-solutions-powered-by-ai/",
    date: "Februrary 2024",
    thumbnail: image2,
    bodyImg: image2,
  },
];

export const blogs = [
  {
    title: "Women shaping India’s water security through social enterprises",
    subTitle:
      "Jagriti Dabas's firm Arms4AI leverages deep technology and GEO-AI to automate satellite based image analysis",
    url: "https://www.indiawaterportal.org/articles/women-shaping-indias-water-security-through-social-enterprises",
    date: "06 March' 2024",
    thumbnail: image3,
    bodyImg: image2,
  },
];

export default function Resources() {
  const carouselRef = useRef(null);

  const handleGoToPrevious = () => {
    goToPrevious(carouselRef);
  };

  const handleGoToNext = () => {
    goToNext(carouselRef);
  };

  return (
    <>
      <PageLoader />
      <div className="containers">
        <div className="blog-head-line">
          <div className="head-line-left">
            <p className="topic">{blog[0].topic}</p>
            <h3>{blog[0].title}</h3>
            <font>{blog[0].subTitle}</font>
            <Link
              to={blog[0].url}
              state={{
                topic: blog[0].topic,
                title: blog[0].title,
                subTitle: blog[0].subTitle,
                // body: blog[0].body,
                date: blog[0].date,
                // thumbnail: blog[0].thumbnail,
                // bodyImg: blog[0].bodyImg,
              }}
            ></Link>
            <button>
              {" "}
              <a href="https://spanmag.com/tech-solutions-powered-by-ai/">
                Read article
              </a>
            </button>

            {/* <a class="" href="https://www.linkedin.com/company/arms4ai/">
              <FaLinkedin className="linkedin-icon" />
            </a> */}
          </div>
          <div
            className="text-[20px] lg:text-[1.5vw] stroke-title mt-[10px] lg:mt-[2vw] w-[90%]"
            style={{ borderRadius: "20px" }}
          >
            <LazyLoadImage src={blog[0].thumbnail} alt="arms4ai" />
          </div>
        </div>
      </div>

      <div className="recent-post">
        <div className="containers">
          <h3 className="recent-heading">Recent Posts</h3>
          <div className="carousals" ref={carouselRef}>
            {blogs.map((blog, index) => (
              <div className="recent-carosal-item" key={index}>
                <LazyLoadImage src={blog.thumbnail} alt="arms4ai" />
                <p className="topic">{blog.topic}</p>
                <Link
                  to={blogs[0].url}
                  state={{
                    topic: blog.topic,
                    title: blog.title,
                    subTitle: blog.subTitle,
                    body: blog.body,
                    date: blog.date,
                    // thumbnail: blog.thumbnail,
                    // bodyImg: blog.bodyImg,
                  }}
                >
                  <h4>{blog.title}</h4>
                </Link>
                <p className="date">{blog.date}</p>
              </div>
            ))}
          </div>
          {/* <div className="slider">
            <div onClick={handleGoToPrevious}>❰</div>
            <div onClick={handleGoToNext}>❱</div>
          </div> */}
        </div>
      </div>

      {/* <div className="containers">
        <h3 className="all-post-heading">All Posts</h3>
        <div className="all-post">
          {blogs.map((blog, index) => (
            <div className="post" key={index}>
              <LazyLoadImage src={blog.thumbnail} alt="arms4ai" />
              <p className="topic">{blog.topic}</p>
              <Link
                to="/blogdetails"
                state={{
                  topic: blog.topic,
                  title: blog.title,
                  subTitle: blog.subTitle,
                  body: blog.body,
                  date: blog.date,
                  thumbnail: blog.thumbnail,
                  bodyImg: blog.bodyImg,
                }}
              >
                <h4>{blog.title}</h4>
              </Link>
              <p className="date">{blog.date}</p>
            </div>
          ))}
        </div>
      </div> */}

      <Footer />
    </>
  );
}
