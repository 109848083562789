import React, { useEffect, useRef } from "react";
import PageLoader from "../components/PageLoader";
import ProductStatItem from "../helpers/ProductStatItem";
import Carosal from "../components/Carosal";
import IntroductionGeo from "../components/IntroductionGeo";
import Client from "../components/Client";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

import images from "../helpers/Images";

const {
  logo,
  elipse1,
  group1,
  group2,
  group3,
  group4,
  group5,
  group6,
  group7,
  group8,
  group9,
  group10,
  vector1,
  vector2,
  rect1,
  rect2,
  rect3,
  rect4,
  rect5,
} = images;

function SampleNextArrow(props) {
  const { style, onClick } = props;

  return (
    <div
      className="hidden-lg lg:block text-[3vw] font-extrabold rounded-[50%] leading-[100%] cursor-pointer w-[max-content] py-[0.5vw] px-[1vw] absolute left-[14vw] top-[18vw] lg:inline-block"
      style={{
        ...style,
        color: "white",
        background: "linear-gradient(97deg, #4C4AA1 4.74%, #C4BFD8 107.11%)",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
      onClick={onClick}
    >
      <span>&gt;</span>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;

  return (
    <div
      className="hidden-lg lg:block text-[3vw] font-extrabold rounded-[50%] leading-[100%] cursor-pointer w-[max-content] py-[0.5vw] px-[1vw] absolute left-[8vw] top-[18vw] lg:inline-block"
      style={{
        ...style,
        color: "white",
        background: "linear-gradient(97deg, #4C4AA1 4.74%, #C4BFD8 107.11%)",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
      onClick={onClick}
    >
      <span>&lt;</span>
    </div>
  );
}

export default function Home() {
  const arm = "ARMS 4 AI";
  const form = useRef();
  const notify = () => toast("Subscribed!");
  const notify2 = () => toast("Error sending email, pls try again later!");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_42oxjkr",
        "template_he1607s",
        form.current,
        "mPVSvcQ8zSxxfGmd3"
      )
      .then((response) => {
        notify();
        console.log("Email successfully sent!", response);
      })
      .catch((error) => {
        notify2();
        console.error("Error sending email:", error);
        // Handle the error as needed (e.g., display an error message to the user)
      });
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const observer = useRef(null);

  const listItemsData = [
    {
      id: "item1",
      number: "01",
      title: "Acquire",
      description:
        "Our platform simplifies the process of preparing satellite images with automated one click tools for data preparation. You can quickly execute basic steps like, merging, mosaicking, layer stacking, spectral index processing etc., in a seamless manner.",
      active: true,
      vid: "https://arms4airesources.s3.amazonaws.com/Acquire.mp4",
    },
    {
      id: "item2",
      number: "02",
      title: "Transform",
      description:
        "Our platform streamlines image processing with advanced algorithms that handle image enhancement, data fusion, and integration. You can easily generate processed images with relevant features, such as vegetation indices, weather data, soil characteristics, and more, without any technical hassles.",
      active: false,
      vid: "https://arms4airesources.s3.amazonaws.com/Transform.mp4",
    },
    {
      id: "item3",
      number: "03",
      title: "Derive insights",
      description:
        "Our platform makes analysis a breeze with machine learning and AI-powered tools. With just a few clicks, you can get proposed power plant locations and generate pixelwise accurate bio energy potential numbers.",
      active: false,
      vid: "https://arms4airesources.s3.amazonaws.com/Derive.mp4",
    },
  ];

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hh = document.getElementById("howhead");
    const hs = document.getElementById("howsub");
    const hl = document.getElementById("howlist");

    const hiddenElements3 = document.querySelectorAll(".how-img");
    hiddenElements3.forEach((el) => observer.current.observe(el));

    document.addEventListener("scroll", function () {
      var rect1 = hh.getBoundingClientRect();
      var rect2 = hl.getBoundingClientRect();
      if ((rect1.top / window.innerWidth) * 100 < 40) {
        hh.style.fontSize = "6vw";
        hh.style.color = "black";
      }
      if ((rect1.top / window.innerWidth) * 100 > 40) {
        hh.style.fontSize = "2vw";
      }
      if ((rect2.top / window.innerWidth) * 100 < 20) {
        hh.style.fontSize = "3vw";
      }

      var rect = hs.getBoundingClientRect();
      var isAtTop = (rect.top / window.innerWidth) * 100 <= 15;
      if (isAtTop) {
        hs.classList.add("fade");
      } else {
        hs.classList.remove("fade");
      }
    });

    var list = document.querySelectorAll(".how-list-item");
    var ilist = document.querySelectorAll(".himg");
    var rede = document.getElementById("rede");
    document.addEventListener("scroll", function () {
      if (ilist.length >= 3) {
        list.forEach((item, index) => {
          const isActive =
            (item.getBoundingClientRect().top / window.innerWidth) * 100 <= 30;
          item.classList.toggle("active", isActive);
          ilist[index].classList.toggle("active", isActive);

          if (isActive) {
            list.forEach((otherItem, otherIndex) => {
              if (otherIndex !== index) {
                otherItem.classList.remove("active");
                ilist[otherIndex].classList.remove("active");
              }
            });
          }
        });
      }
    });

    const inputMin = 0;
    const inputMax = -40;
    const outputMin = 0;
    const outputMax = 1.5;
    const tech = document.getElementById("technology"); // Replace with your actual element ID
    const carosal = document.getElementById("carosal"); // Replace with your actual element ID

    let techRect = tech.getBoundingClientRect();
    let carosalRect = carosal.getBoundingClientRect();

    function updateFontSize() {
      techRect = tech.getBoundingClientRect();
      carosalRect = carosal.getBoundingClientRect();
      requestAnimationFrame(() => {
        if (
          (techRect.top / window.innerWidth) * 100 < 7 &&
          (techRect.top / window.innerWidth) * 100 > 3
        ) {
          const mappedValue =
            (((carosalRect.top / window.innerWidth) * 100 - inputMin) /
              (inputMax - inputMin)) *
              (outputMax - outputMin) +
            outputMin;
          tech.style.fontSize = 3 - mappedValue + "vw";
        }
      });
    }

    document.addEventListener("scroll", function () {
      setInterval(updateFontSize, 200);
      if ((tech.getBoundingClientRect().top / window.innerWidth) * 100 < 7) {
        rede.style.opacity = 0;
      } else {
        rede.style.opacity = 1;
      }
    });

    //const prev = document.getElementById('prev');
    //const next = document.getElementById('next');
    //const moveButton = document.getElementsByClassName('slick-track');
    //let currentPosition = 0;

    /*prev.addEventListener('click', () => {
        if( currentPosition > 0){
          currentPosition--;
          moveButton[0].style.transform  = 'translate3d('+ (-currentPosition * 450) + 'px, 0, 0)';
        }
    });

    next.addEventListener('click', () => {
      if( currentPosition < 4){
        currentPosition++;
        moveButton[0].style.transform  = 'translate3d('+ (-currentPosition * 450) + 'px, 0, 0)';
      }
    });*/

    return () => {};
  }, []);

  return (
    <>
      <PageLoader />
      <div className="bg-eclipse-dark relative lg:pb-[100px]">
        <div className="containers">
          <div className="pb-[200px] lg:pb-[16vh] pt-[200px] lg:pt-[20vh] lg:flex lg:justify-between">
            <h1 className="text-white text-[48px] lg:text-[5vw] leading-none font-extrabold stroke-heading relative z-[3] lg:flex-[3]">
              Geo - AI
              <br /> Precision Made <br />
              Simple
            </h1>
            <div className="lg:pt-[25vh] pt-[50px] relative z-[3] lg:flex-[2] lg:leading-[1.4vw]">
              <font className="text-white text-[12px] lg:text-[1vw] tracking-[1px] ">
                Revolutionize your geospatial analytics with
                <br />
                <font className="a90">90%+ accuracy</font> through our Geo-AI
                Platform that
                <br />
                empowers users in Agriculture, Infrastructure,
                <br /> Defence, and Environment sectors to make
                <br /> informed decisions with ease.
              </font>
            </div>
          </div>
        </div>

        <div className="containers mt-[34vh] pb-[10px] lg:flex relative z-[3] gap-[2vw]">
          <div className="lg:h-[30vh] lg:w-[20vw] production-item1">
            <ProductStatItem
              imageSrc={group1}
              number="03"
              title="Continents Serving"
              description="Operating across Asia, America, and Africa, showcasing our global presence and reach."
            />
          </div>
          <div className="bg-gray-500 h-[4vw] w-[1px] hidden mt-[30px] lg:block"></div>
          <div className="lg:h-[30vh] lg:w-[20vw] mt-10 lg:mt-0 production-item2">
            <ProductStatItem
              imageSrc={group2}
              number="06"
              title="Government Recognitions and Work Orders"
              description="Celebrating six prestigious government recognitions and work orders, validating our commitment to excellence."
            />
          </div>
          <div className="bg-gray-500 h-[4vw] w-[0.9px] hidden  mt-[30px]  lg:block"></div>
          <div className="lg:h-[30vh] lg:w-[24vw]  mt-10 lg:mt-0 production-item3">
            <ProductStatItem
              imageSrc={group3}
              number="04"
              title="Industry Verticals, 12 Ready-to-Deploy Products:"
              description="Excelling in four industry verticals with a range of twelve fully developed and ready-to-deploy products, empowering clients with cutting-edge solutions."
            />
          </div>
        </div>
        <div className="absolute top-[0px] right-[0px]  h-[200px] w-[250px] lg:h-[22vw] lg:w-[27vw]">
          <svg
            width="450"
            height="304"
            viewBox="0 0 500 584"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              id="circle-path"
              cx="388.5"
              cy="95.5"
              r="387.5"
              stroke="white"
              stroke-opacity="0.26"
              stroke-width="1.6"
            />
            <circle class="moving-blur-circle" r="10" fill="white" />
          </svg>
        </div>

        <div className="absolute top-[34vw] right-0 z-[0] w-[100%] h-[100vw] hidden sm:block">
          <svg
            className="svg-element"
            width="100%"
            height="436"
            viewBox="120 0 1820 476"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              id="ellipse-path"
              cx="828"
              cy="995"
              rx="1334"
              ry="994"
              stroke="#B6AFAF"
              stroke-width="2"
            />
            <circle class="moving-circle" r="10" fill="white" />
            <circle class="moving-circle-2" r="10" fill="white" />
          </svg>
        </div>
        {/* <div>
          <LazyLoadImage
            src={elipse1}
            alt=""
            className="absolute top-[600px] w-[100%] block lg:hidden"
          />
        </div> */}
      </div>

      <div className="bg-gradient-white relative z-[3]">
        <div className="containers-1">
          <div className="pb-8 lg:pb-[16vh] pt-8 lg:pt-[20vh] lg:flex lg:justify-between">
            <div className="flex gap-[2vw]">
              <div className="flex-[1] pb-[3vw]">
                <h1 className="text-[1.8vw] font-bold mt-[3vw]">
                  Leveraging Deep technology and
                  <br /> GEO-AI to automate satellite based
                  <br /> image analysis with Single click 
                </h1>
                <h1
                  className="text-[2.1vw] font-bold mt-[2.5vw]"
                  style={{ color: "#FFB831", fontSize: "30px" }}
                  id="text-changing"
                ></h1>
                <div className="flex gap-[2.5vw] mt-[4vw]">
                  <div className="flex-[2]">
                    <p className="text-[1.2vw] mt-[0.2vw]">
                      Saving costs and manpower upto
                    </p>
                    <h5 className="text-[0.9vw] stroke-title mt-[1.9vw]">
                      70%
                    </h5>
                  </div>
                  <div
                    className="w-[1px] h-[5vw]"
                    style={{ backgroundColor: "#949494" }}
                  ></div>
                  <div className="flex-[3]">
                    <p className="text-[1.2vw]">
                      Delivering analysis ready data and
                      <br /> decision making in B2B and B2G
                      <br /> sector offered as 
                    </p>
                    <h5 className="text-[0.9vw] stroke-title  mt-[0.5vw]">
                      Subscription as a Service (SaaS)
                    </h5>
                  </div>
                </div>
              </div>
              <div className="flex-[1]  " style={{ borderRadius: "20px" }}>
                <video
                  width="140%"
                  height="140%"
                  autoPlay={true}
                  muted
                  loop
                  className="worldvideo"
                  style={{
                    background: "transparent",
                    borderRadius: "40px",
                    marginTop: "5rem",
                  }}
                >
                  <source
                    src="https://arms4airesources.s3.amazonaws.com/World.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>

        <div className="containers">
          <div className="mx-auto pb-[200px] lg:pb-[12vw] pt-[200px] lg:pt-[0vw]">
            <div
              className="sticky top-[6vw] pb-[35vw] hidden lg:block text-[3vw] font-extrabold how-heading"
              id="howhead"
            >
              <div className="h-[10vw]">
                <font classList="block" style={{ color: "#03085D" }}>
                  How we are doing it ?
                </font>
              </div>
            </div>
            <div className="block lg:hidden text-[35px] font-extrabold">
              <font style={{ color: "#03085D" }}>How we are doing it ?</font>
            </div>
            <div className="block lg:hidden text-[14px] mt-[30px]">
              <p>
                In the Agriculture and Energy industries, with our holistic
                approach of procuring satellite data and completing the journey
                to advanced analytics and decision-making, we provide an
                easy-to-use solution for estimating bioenergy from rice
                residues.
                <br />
                <br></br>
                Our technology-driven approach enables stakeholders to make
                data-driven decisions, optimizing waste management practices,
                and improving the supply chain of agricultural waste.
                <br />
                <br />
                <div className=" text-[12px]">
                  {" "}
                  Let's take a look with an example
                </div>
              </p>
            </div>
            <div
              className="hidden lg:block text-[1.6vw] leading-[2vw] mt-[-45vh] pl-[30vw]"
              id="howsub"
            >
              <p>
                In the Agriculture and Energy industries, with our holistic
                approach of procuring satellite data and completing the journey
                to advanced analytics and decision-making, we provide an
                easy-to-use solution for estimating bioenergy from rice
                residues.
                <br />
                <br></br>
                Our technology-driven approach enables stakeholders to make
                data-driven decisions, optimizing waste management practices,
                and improving the supply chain of agricultural waste.
                <br />
                <br />
                <div className=" text-[1.5vw]">
                  {" "}
                  Let's take a look with an example
                </div>
              </p>
            </div>
            <div className="gap-[50px] mt-[100px] lg:mt-[300px] lg:mb-[5vw] justify-center how-holder">
              <div className="how-img hidden lg:block mb-[10vw]" id="howlist">
                <div className="himg active">
                  <video
                    width="140%"
                    height="140%"
                    autoPlay={true}
                    muted
                    loop
                    style={{
                      borderRadius: "24px",
                    }}
                  >
                    <source
                      src="https://arms4airesources.s3.amazonaws.com/Acquire.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="himg">
                  <video
                    style={{
                      borderRadius: "24px",
                    }}
                    width="140%"
                    height="140%"
                    autoPlay={true}
                    muted
                    loop
                  >
                    <source
                      src="https://arms4airesources.s3.amazonaws.com/Transform.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="himg">
                  <video
                    style={{
                      borderRadius: "24px",
                    }}
                    width="140%"
                    height="140%"
                    autoPlay={true}
                    muted
                    loop
                  >
                    <source
                      src="https://arms4airesources.s3.amazonaws.com/Derive.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="how-list hidden lg:block lg:mt-[5vw]">
                {listItemsData.map((item) => (
                  <div
                    key={item.id}
                    className={`how-list-item ${item.active ? "active" : ""}`}
                    id={item.id}
                  >
                    <h6>{item.number}</h6>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                ))}
              </div>

              <div className="block lg:hidden" style={{ marginTop: "-10rem" }}>
                {listItemsData.map((item) => (
                  <div key={item.id}>
                    <div className="how-list-item-small active">
                      <h6>{item.number}</h6>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                      <video
                        style={{
                          marginTop: "3rem",
                          // marginLeft: "2rem",
                          borderRadius: "24px",
                        }}
                        width="140%"
                        height="140%"
                        autoPlay={true}
                        muted
                        loop
                      >
                        <source src={item.vid} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
      <div className="bg-white">
        <div className="w-[100%] h-[50px] hidden lg:block mt-[15vh] overflow-hidden">
          <LazyLoadImage src={vector1} alt="" className="w-[100%] h-[100%]" />
        </div>
        <div>
          <font
            className="ml-[8%] text-[20px] lg:text-[3vw] lg:pb-[50vw] font-extrabold stroke-title block text-center lg:text-left hidden lg:block"
            style={{ color: "#03085D" }}
            id="technology"
          >
            Technology{" "}
            <font style={{ color: "#FFB831" }} id="rede">
              Redefined
            </font>
          </font>
          <font
            className="text-[20px] font-extrabold stroke-title block text-center block lg:hidden"
            style={{ color: "#03085D" }}
          >
            Technology <font style={{ color: "#FFB831" }}>Redefined</font>
          </font>
          <div className="containers lg:mt-[-60vw]" id="carosal">
            <div className="mx-auto pb-[100px] lg:pb-[5vw] pt-[10px] lg:pt-[7vw]">
              <div className="lg:flex lg:gap-[50px] justify-end ">
                <div className=" lg:w-[45%]">
                  <font className="text-[12px] lg:text-[1.5vw] leading-snug block text-center lg:text-left mt-[20px] lg:mt-[-3vw]">
                    Experience the future with cutting-edge <br />
                    geospatial modeling, advanced computer vision, <br />
                    and powerful machine learning techniques to <br />
                    transform satellite image data into actionable <br />
                    insights across diverse sectors.
                  </font>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[100%] h-[50px] hidden lg:block mt-[10vh]  overflow-hidden">
            <LazyLoadImage src={vector2} alt="" className="w-[100%] h-[100%]" />
          </div>

          <div className="mx-auto pb-[150px] lg:pb-[15vw] pt-[0px] lg:pt-[15vw]">
            <div className="carosal-wrapper">
              <Carosal />
            </div>
          </div>
        </div>

        <div className="mx-auto pb-[150px] lg:pb-[150px] pt-[0px] lg:pt-[150px]">
          <IntroductionGeo />
        </div>

        <div className="mx-auto pb-[150px]">
          <Client />
        </div>
      </div>

      <div className="mx-auto  pb-[50px] lg:pb-[5vw] pt-[50px] lg:pt-[5vw">
        <div className="home-footer relative lg:my-[10vw] my-[200px]">
          <div className="footer-left px-[8%] lg:pt-[2vw]">
            <h1 className="stroke-title">
              Industries <br />
              we serve
            </h1>
          </div>
          <div className="footer-right " id="footer">
            <Slider {...settings}>
              <div className="small-slider">
                <div className="slider-name-bot">
                  <LazyLoadImage
                    src="https://arms4airesources.s3.amazonaws.com/Rectangle130.webp"
                    alt="arms4ai"
                  />
                  <p style={{ paddingTop: "20px", fontSize: "18px" }}>
                    Infrastructure & Real Estate
                  </p>
                </div>
              </div>

              <div className="small-slider">
                <div className="slider-name-bot">
                  <LazyLoadImage src={rect2} alt="arms4ai" />
                  <p style={{ paddingTop: "20px", fontSize: "18px" }}>
                    Agriculture and Energy
                  </p>
                </div>
              </div>
              <div className="small-slider">
                <div className="slider-name-bot">
                  <LazyLoadImage src={rect3} alt="arms4ai" />
                  <p style={{ paddingTop: "20px", fontSize: "18px" }}>
                    Environment
                  </p>
                </div>
              </div>
              <div className="small-slider">
                <div className="slider-name-bot">
                  <LazyLoadImage src={rect4} alt="arms4ai" />
                  <p style={{ paddingTop: "20px", fontSize: "18px" }}>
                    Defence
                  </p>
                </div>
              </div>
              <div className="small-slider">
                <div className="slider-name-bot">
                  <LazyLoadImage src={rect5} alt="arms4ai" />
                  <p style={{ paddingTop: "20px", fontSize: "18px" }}>
                    Academics
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="blue-gradient">
        <div className="containers">
          <div className="py-[15vw] ">
            <h1 className="text-white text-[30px] lg:text-[3vw] stroke-title w-[100%] lg:w-[50%]">
              We focus on building solutions that provide
            </h1>
            <div className="lg:flex mt-[30px] lg:mt-[6vw] gap-[10vw]">
              <div className="flex gap-[20px] lg:gap-[2vw]">
                <LazyLoadImage
                  src={group4}
                  alt=""
                  className="w-[30px] h-[30px] lg:w-[4vw] lg:h-[4vw]"
                />
                <h2 className="text-[10px] lg:text-[1.3vw] rozha text-white w-[100%] lg:w-[15vw]">
                  High Efficiency and <br />
                  Productivity
                </h2>
              </div>
              <div className="flex gap-[20px]  lg:gap-[2vw] mt-[20px] lg:mt-[0px]">
                <LazyLoadImage
                  src={group5}
                  alt=""
                  className="w-[30px] h-[30px] lg:w-[4vw] lg:h-[4vw]"
                />
                <h2 className="text-[10px] lg:text-[1.3vw] rozha text-white w-[100%] lg:w-[15vw]">
                  Infrastructure and high
                  <br />
                  Resource Economy
                </h2>
              </div>
              <div className="flex gap-[20px]  lg:gap-[2vw] mt-[20px] lg:mt-[0px]">
                <LazyLoadImage
                  src={group7}
                  alt=""
                  className="w-[30px] h-[30px] lg:w-[4vw] lg:h-[4vw]"
                />
                <h2 className="text-[10px] lg:text-[1.3vw] rozha text-white w-[100%] lg:w-[15vw]">
                  Process <br />
                  Automation
                </h2>
              </div>
            </div>
            <div className="lg:flex mt-[30px] lg:mt-[4vw] gap-[10vw]">
              <div className="flex gap-[20px]  lg:gap-[2vw]">
                <LazyLoadImage
                  src={group8}
                  alt=""
                  className="w-[30px] h-[30px] lg:w-[4vw] lg:h-[4vw]"
                />
                <h2 className="text-[10px] lg:text-[1.3vw] rozha text-white w-[100%] lg:w-[15vw]">
                  Better Utilization <br />
                  of Data
                </h2>
              </div>
              <div className="flex gap-[20px]  lg:gap-[2vw] mt-[20px] lg:mt-[0px]">
                <LazyLoadImage
                  src={group6}
                  alt=""
                  className="w-[30px] h-[30px] lg:w-[4vw] lg:h-[4vw]"
                />
                <h2 className="text-[10px] lg:text-[1.3vw] rozha text-white w-[100%] lg:w-[15vw]">
                  Measurement <br />
                  Accuracy
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" hidden lg:block white-pink">
        <div className="mt-[200px] lg:mt-[20vw] containers">
          <h1 className="text-center text-[28px] lg:text-[1.6vw] stroke-title">
            Space Intelligence Made User-Friendly for Businesses. Get started
            with ARMS 4 AI today
          </h1>
          <div className="flex justify-center">
            <div className="mt-[5vw] py-[1vw] px-[2vw] blue-gradient-button w-[max-content] rounded-[5vw]">
              <p className="text-[1vw] text-white">
                <a class="" href="/contact">
                  Contact Us
                </a>
              </p>
            </div>
          </div>
          <div className="mt-[6vw] flex gap-[10vw]">
            <div className="flex-[4]">
              <LazyLoadImage src={logo} alt="" className="w-[6vw] h-[3vw]" />
              <p className="mt-[2vw] text-[1vw] w-[30vw]">
                ARMS 4 AI utilises emerging technologies from the 4th industrial
                revolution like Artificial intelligence (AI), deep learning and
                machine learning which effectively bridges the technology and
                capability gap.We provide end to end solutions having “economy
                of effort, scalability, better efficiency and optimisation.”
              </p>
              <div className="flex mt-[5vw] gap-[3vw]">
                <div className="w-[100%]">
                  <h2
                    className="text-[1vw] rozha font-extrabold"
                    style={{ color: "#03085D" }}
                  >
                    Company
                  </h2>
                  <div className="flex justify-between w-[100%] mt-[1vw]">
                    <NavLink to="/about">
                      <p className="text-[1vw] font-extrabold">About us</p>
                    </NavLink>
                    <NavLink to="/product">
                      <p className="text-[1vw] font-extrabold">Product</p>
                    </NavLink>
                    <NavLink to="/industry">
                      <p className="text-[1vw] font-extrabold">
                        Industry Verticals
                      </p>
                    </NavLink>
                    <NavLink to="/resources">
                      <p className="text-[1vw] font-extrabold">Resources</p>
                    </NavLink>
                  </div>
                </div>
                <div
                  className="h-[4vw] w-[1px] "
                  style={{ backgroundColor: "#949494" }}
                ></div>
                <div>
                  <h2
                    className="text-[1vw] rozha font-extrabold w-[max-content]"
                    style={{ color: "#03085D" }}
                  >
                    Connect with us
                  </h2>
                  <div className="flex gap-[2vw]">
                    <a class="" href="https://twitter.com/ARMS 4 AI1">
                      <LazyLoadImage
                        src={group9}
                        alt=""
                        className="h-[1.3vw] w-[1.3vw] mt-[1vw]"
                      />
                    </a>
                    <a
                      class=""
                      href="https://www.linkedin.com/company/ARMS 4 AI/"
                    >
                      <LazyLoadImage
                        src={group10}
                        alt=""
                        className="h-[1.3vw] w-[1.3vw] mt-[1vw]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-[3]">
              <div className="blue-gradient px-[2vw] py-[4vw] rounded-[1vw]">
                <h2 className="text-white text-[1.5vw] rozha">
                  Subscribe to our Newsletter
                </h2>
                <p className="text-white text-[0.9vw] mt-[2vw]">
                  Space Intelligence Made User-Friendly for Businesses. Get
                  started with ARMS 4 AI today
                </p>
                <p className="text-white text-[0.8vw] mt-[2.5vw] mb-[0.5vw]">
                  Email
                </p>
                <div className="flex gap-[2vw]">
                  <form ref={form}>
                    <input
                      type="email"
                      name="email"
                      className="rounded-[3vw] flex-[4] text-[1.5vw] 
                     "
                      style={{
                        fontSize: "1rem",
                        height: "2.2vw",
                        textAlign: "center",
                        width: "15vw",
                      }}
                    />
                  </form>
                  <div className="flex-[1] py-[0.5vw] px-[1.5vw] blue-gradient-button w-[max-content] rounded-[3vw]">
                    <div
                      className="text-[0.8vw] text-white text-center"
                      onClick={sendEmail}
                      style={{ cursor: "pointer" }}
                    >
                      Subscribe
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-[100%] h-[1px] mt-[10vw] hidden lg:block"
          style={{ backgroundColor: "#949494" }}
        ></div>
        <div className="flex justify-between containers">
          <p className="text-[0.8vw] my-[1vw]">
            Copyright © 2023 ARMS 4 AI Private Limited
          </p>
          <div className="flex gap-[5vw]  my-[1vw]">
            <p className="text-[0.8vw]">Terms of Use</p>
            <p className="text-[0.8vw]">Privacy Policy</p>
            <p className="text-[0.8vw]">
              Copyright & Intellectual Property Policy
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
