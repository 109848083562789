import React from "react";
import client1 from "../assets/acir-logo1.png";
import client2 from "../assets/atl-logo1.png";
import client3 from "../assets/hexgn-logo1.png";
import client4 from "../assets/ib-logo1.png";
import client5 from "../assets/meity-logo1.png";
import client6 from "../assets/nexus-logo1.png";
import client7 from "../assets/pontag-logo1.png";
import client8 from "../assets/startup-india-logo1.png";
import client9 from "../assets/wehub-logo1.png";
import client10 from "../assets/mhlogo.jpeg";
import client11 from "../assets/kzk.jpeg";
import client12 from "../assets/thane.png";
import client13 from "../assets/dcm.png";
export default function Client() {
  return (
    <div className="clients-holder">
      <h1 className="text-[28px] lg:text-[4vw] font-extrabold stroke-title lg:my-[5vw] my-[50px]">
        Clients and Incubators
      </h1>
      <div className="clients-wrapper">
        <div className="clients">
          <div className="client-img">
            <img src={client8} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client13} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client12} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client11} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client10} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client9} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client2} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client3} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client7} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client4} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client5} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client6} alt="arms4ai" />
          </div>

          <div className="client-img">
            <img src={client1} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client8} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client9} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client2} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client3} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client7} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client4} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client5} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client6} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client1} alt="arms4ai" />
          </div>
          <div className="client-img">
            <img src={client8} alt="arms4ai" />
          </div>
        </div>
      </div>
    </div>
  );
}
